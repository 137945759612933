import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import VueGtag from "vue-gtag"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginVue from "@bugsnag/plugin-vue"

if (process.env.VUE_APP_ENV === "production") {
  Vue.use(VueGtag, {
    config: { id: "G-FTV9FB12Q6" }
  })

  Bugsnag.start({
    apiKey: "db9117c943c26e54c97d6ff205d899df",
    plugins: [new BugsnagPluginVue()]
  })

  const bugsnagVue = Bugsnag.getPlugin("vue")
  bugsnagVue.installVueErrorHandler(Vue)
}

/**
 * App Kit.
 */
import "@/appkit"
import "@/assets/styles/app.pcss"

/**
 * Drift.
 */
import DriftPlugin from "@/plugins/DriftPlugin"
Vue.use(DriftPlugin, { appId: "xukef564atu3" })

/**
 * AG Shared Assets.
 */
Vue.use(MarkdownPlugin)
Vue.use(SanitisePlugin)

import {MarkdownPlugin, SanitisePlugin} from "@syntax51/ag-shared-assets"
/**
 * Layouts
 */
import AppLayout from "@/layouts/App"
import AdminOnly from "@/components/AdminOnly.vue"

Vue.component("AppLayout", AppLayout)
Vue.component("AdminOnly", AdminOnly)

Vue.config.productionTip = false

/**
 * Global Variables
 */
Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
